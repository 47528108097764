import Vue from 'vue'

// axios
import axios from 'axios'

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  // Local server
  // baseURL: 'http://127.0.0.1:8000/api/v1',

  // Live Server
  baseURL: 'https://api.cromis.app/api/v1',
  // Mkwawa cromis live
  // baseURL: 'https://api.buying.mkwawa.co.tz/api/v1',
  // Mkwawa cromis test
  // baseURL: 'https://api.test.mkwawa.co.tz/api/v1',
  // Test Server
  // baseURL: 'https://dev.cromis.app/api/v1',
  // timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar'}
})

Vue.prototype.$http = axiosIns

export default axiosIns
